import React from "react";
import Instagram from "../../assets/instagram.png";
import Logo from "../../assets/logo.png";
import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer-container">
            <hr />
            <div className="footer">
                <div className="social-links">
                    <a href="https://www.instagram.com/grind.on__" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Instagram" />
                    </a>
                </div>
                <div className="logo-f">
                    <img src={Logo} alt="Grind'On Strength & Fitness" />
                </div>
                <div className="gym-info">

                    <h2>Grind'On Strength & Fitness</h2>
					<p> +977 9863032963 | +977 9818950290 </p>
<br></br>
                    <p>Bhaktapur, Sallaghari</p>
					<br></br><br></br>
                </div>
            </div>

            <div className="blur blur-f-1"></div>
            <div className="blur blur-f-2"></div>
        </div>
    );
};

export default Footer;