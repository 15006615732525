import React from 'react'
import './Programs.css'
import {programsData} from '../../data/programsData';
import RightArrow from'../../assets/rightArrow.png'

const Programs = () => {
  return (
    <div className="Programs" id='programs'>
        <div className='programs-header'>
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>To Shape Your</span>
        </div>

        <div className="program-categories">
            {programsData.map((program) => (
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                              <a href="tel:+9779863032963" className="join-now-link">
                        <div className="join-now">
                            <span>Join Now</span>
                            <img src={RightArrow} alt="right arrow" />
                        </div>
                    </a>
               </div>
            ))}
        </div>
    </div>
  )
}

export default Programs