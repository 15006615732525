import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/dlplatforms.jpeg";
import adidias from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";

const Reasons = () => {
	return (
		<div className="Reasons" id="reasons">
			<div className="left-reasons">
				<img src={image1} alt="" />
				<img src={image2} alt="" />
				<img src={image3} alt=""  />
				<img src={image4} alt="" />
			</div>
			<div className="right-reasons">
				<span>some reasons</span>
				<div>
					<span className="stroke-text">why</span>
					<span className="choose">choose us?</span>
				</div>

				<div className="details-r">
					<div>
						<img src={tick} alt=""></img>
						<span>OVER 10 Years of Experience</span>
					</div>
					<div>
						<img src={tick} alt="" />
						<span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
					</div>
					<div>
						<img src={tick} alt="" />
						<span>Premium OFFERING WITH MINIMAL PRICING</span>
					</div>
					<div>
						
						<img src={tick} alt="" />
						<span>BEGINEER FRIENDLY SUPPORT</span>
					</div>
				</div>

				<span
					style={{
						color: "var(--gray)",
						fontWeight: "normal",
					}}>
					OUR PARTNER
				</span>

				<div className="partners">
					<a href="https://dlplatforms.com/" target="_blank" rel="noopener noreferrer">
						<img src={nb} alt="Partner Logo" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default Reasons;
